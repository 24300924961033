<template>
  <consulta ref="modal" :columns="columns" :process="process" id="consultar-registro-auxiliar"
            :titulo="`Consultar ${title}`" :mlAuto="!marcados.length"
            :exportar="exportar"
            :custom-actions="actions">

    <template #cabecalho>

      <li class="list-inline-item" v-if="multiplos">
        <a @click.prevent="selecionarTodos()" title="Selecionar Todos" class="icon">
          <i class="fal fa-check-circle"></i>
        </a>
      </li>

      <li class="list-inline-item" v-if="multiplos">
        <a @click.prevent="selecionarTodos(true)" title="Limpar Seleção" class="icon">
          <i class="fal fa-circle"></i>
        </a>
      </li>

    </template>

    <template #filtros>

      <label for="modo-pesquisa" v-if="!$root.config.geral.desativarModeloAntigo">
        <input id="modo-pesquisa" type="checkbox" v-model="mode" true-value="novo" false-value="default" class="mt-2 mr-1">
        Utilizar novo modelo de consulta
      </label>

      <div class="w-100"></div>

      <filtrar-consulta class="w-100" v-if="mode != 'default'" :options="opcoesConsulta" :dto="filtros">
        <template #custom="{item}">
          <template v-if="item.id == 'envolvido_nome'">
            <v-input type="v-multi-select"
                     @change="changePapel"
                     @click="changePapel"
                     v-model="filtros.envolvidoPapel" :options="options.tiposPapeis"  class="col-auto"/>
          </template>

          <template v-if="item.id === 'indicador_real_id'">
            <v-input class="col-auto pr-0" v-model="buscar" :options="listas.livros" type="select" />
            <buscar-fichas :valorInicialIndividual="indicador_real" label="Matrículas Relacionadas" :livro="livroSelecionado" :status="statusSelecionado" :full="true"
                           ref="buscarFichas" @selecionar="selecionarFichas('indicador_real', ...arguments)" :multiple="false" class="pl-2 col"/>
          </template>
        </template>
      </filtrar-consulta>

      <template v-if="mode == 'default'">

        <template v-if="status !== 'PENDENTE'">
          <v-input v-model="filtros.digitalizacao" :options="options.tiposDigitalizacao" type="select" label="Digitalização" class="col-2" />
        </template>

        <div class="w-100"></div>

        <v-input type="text" label="Registro" v-model="filtros.codigo" class="col" />
        <v-input v-model="filtros.tipoRegistro" :options="options.tiposRegistro" type="select" label="Tipo de Registro Auxiliar" class="col" />

        <v-input v-if="filtros.tipoRegistro === 'CEDULA'" v-model="filtros.tipoCedula" :options="options.tiposCedulas" type="select" label="Tipo de cédulas" class="col" />

        <v-input v-if="filtros.tipoRegistro === 'PACTO'" v-model="filtros.dataPacto"  type="date" label="Data do pacto" class="col" />

        <v-input v-if="filtros.tipoRegistro === 'OUTROS'" v-model="filtros.descricaoTipoRegistroOutros" type="text" label="Descrição" class="col" />

        <v-input v-if="filtros.tipoRegistro === 'CONVENCAO_CONDOMINIO'"  v-model="filtros.condominio" type="text" placeholder="Nome do condomínio" label="Nome do condomínio"  class="col"/>

        <template v-if="status !== 'PENDENTE'">
          <v-input type="date" label="Data Abertura (Início)" :required="true" v-model="filtros.dataAberturaInicio" class="col-2"/>
          <v-input type="date" label="Data Abertura (Fim)" :required="true" v-model="filtros.dataAberturaFim" class="col-2"/>


          <div class="w-100"></div>
          <v-input type="text" label="Minuta" v-model="filtros.minuta" class="col"/>
          <v-input v-model="filtros.tipoAto" :options="options.tiposAto" type="select" label="Tipo de Ato" class="col" />
          <v-input type="date" label="Data Registro (Início) " v-model="filtros.registroInicio" class="col-2" />
          <v-input type="date" label="Data Registro (Fim)" v-model="filtros.registroFim" class="col-2" />
        </template>
          <card class="card-full mt-2" titulo="Identificação">
            <card-content class="row gutters-xs">
              <v-input class="col-2" v-model="filtros.livroLetra" type="text"  label="Livro (Letra)" />
              <v-input v-if="filtros.tipoRegistro === 'CEDULA'" v-model="filtros.dataVencimentoCedula"  type="date" label="Vencimento" class="col-2" />
              <v-input v-if="filtros.tipoRegistro === 'CEDULA'" v-model="filtros.valorCedula"  type="currency" label="Valor" class="col-2" />

            </card-content>

          </card>
          <template v-if="status !== 'PENDENTE'">

          <card class="card-full" titulo="Envolvidos" key="envolvidos" chave="envolvidos">
            <card-content class="row gutters-xs">
              <div class="w-100"></div>
              <v-input type="text" label="CPF/CNPJ" v-model="filtros.envolvidoDocumento" class="col-3" />
              <v-input type="text" label="Nome" v-model="filtros.envolvidoNome" class="col" />
              <v-input type="select" label="Papel" v-model="filtros.envolvidoPapel" :options="options.tiposPapeis" class="col-3" />
            </card-content>
          </card>
        </template>

        <card class="card-full mt-2" titulo="Matrículas Relacionadas">
          <card-content class="row gutters-xs">
            <v-input class="col-auto pr-0" v-model="buscar" :options="listas.livros" type="select" />
            <buscar-fichas :valorInicialIndividual="indicador_real" label="Matrícula" :livro="livroSelecionado" :status="statusSelecionado" :full="true"
                           ref="buscarFichas" @selecionar="selecionarFichas('indicador_real', ...arguments)" :multiple="false" class="pl-2 col"/>
          </card-content>
        </card>
      </template>

    </template>

    <template #row="{ row }">

<!--      <tr @click="select(row)" class="pointer">-->
      <tr>

        <td width="50px" class="text-center" v-if="multiplos">
          <input :id="`check-${row.id}`" class="form-check-input" @click="selectRegistro(row)"
                 style="margin: -5px 0 0 -6px;position: relative;" type="checkbox" v-model="row.selecionado">
        </td>

        <td width="50px" class="text-center">
          <a href class="icon" @click.prevent.stop="detalhes(row.id)">
             <i class="fal fa-landmark"></i>
          </a>
        </td>

        <td width="140px" class="pointer icon" @click="select(row)">
          <a>{{ row.codigo | inteiro }}{{row.livroLetra ? `-${row.livroLetra}` : ''}}</a>
        </td>
        <template v-if="status !== 'PENDENTE'">
          <td>{{ row.abertura | datahora }}</td>
          <td>{{ row.encerramento | datahora }}</td>
        </template>

        <td width="120px" class="text-capitalize">{{ row.status.toLowerCase() }}</td>
        <td>{{ tipoRegistro(row.tipoRegistro) }}</td>

      </tr>

    </template>
    <template #footer  v-if="!$root.usuarioLogado.auditor">

      <v-button v-if="marcados.length > 0" class="btn btn-outline-primary mr-2 ml-auto" :run="select" :params="[marcados]">
        Adicionar
      </v-button>

    </template>

  </consulta>
</template>

<script>
  import AnexoBusiness from "@/business/AnexoBusiness.js";
  import FrontBusiness from "@/business/FrontBusiness.js";
  import RegistroAuxiliarBusiness from "@/business/indicadores/RegistroAuxiliarBusiness.js";
  import FiltrarConsulta from "@/components/Consultas/components/FiltrarConsulta.vue";
  import Consulta from "@/components/Consultas/Consulta.vue";
  import ModalFichaAntiga from "@/components/ModalFichaAntiga.vue";
  import DetalhesRegistroAuxiliar from "@/components/RegistroAuxiliar/Detalhes/DetalhesRegistroAuxiliar.vue";
  import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness.js";
  import Card from "@/components/Layout/components/Card.vue";
  import CardContent from "@/components/Layout/components/CardContent.vue";
  import Utils from "@/commons/Utils.js";
  import TipoServicoBusiness from "@/business/crud/TipoServicoBusiness.js";
  import RelatorioBusiness from "@/business/RelatorioBusiness.js";
  import ConsultarRegistroAuxiliar from "@/components/RegistroAuxiliar/ConsultarRegistroAuxiliar.vue";
  import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness.js";
  import ImprimirFichaLote from "@/components/Livros/ImprimirFichaLote.vue";
  import DigitalizarFichaLote from "@/components/Livros/DigitalizarFichaLote.vue";
  import ConsultarRelatorio from "@/components/Consultas/ConsultarRelatorio.vue";
  import ArquivoRelatorioBusiness from "@/business/relatorios/ArquivoRelatorioBusiness";

export default {
    name: "ConsultarRegistroAuxiliar",
    components: {FiltrarConsulta, Consulta, Card, CardContent},
    props: {
      multiplos : {type : Boolean, default : false},
      seleciona : {type : Boolean, default : true},
      status : String // PENTENDE, ABERTA, NULL
    },
    data() {

      return {
        buscar : 'MATRICULA',
        indicador_real: '',
        mode: 'novo',
        opcoesConsulta: RegistroAuxiliarBusiness.camposConsulta().filter(i => {
          return !i.status || (i.status && !this.status) || (this.status && i.status && (i.status).includes(this.status))}).filter(l => l.id != 'envolvido_papel'),
        filtros : {
          filtros: [],
          codigo: null,
          status : this.status,
          envolvidoDocumento: null,
          envolvidoNome: null,
          digitalizacao: '',
          matricula: null,
          tipoBuscaEnvolvidoDocumento: 'contains'
        },
        options: {
          tiposRegistro: RegistroAuxiliarBusiness.getTipos(),
          tiposCedulas: RegistroAuxiliarBusiness.getTiposCedulas(),
          tiposPessoa: IndicadorPessoalBusiness.getTiposPessoa(),
          todosTiposPapeis: [{id:'TODOS', nome:'Todos os papéis'}],
          tiposPapeis: this.todosTiposPapeis,
          tiposAto: [],
          tiposDigitalizacao: AnexoBusiness.tiposDigitalizacao(),
          tiposBusca: FrontBusiness.tiposBuscaFiltro('cpfCnpj'),
        },
        marcados: [],
        listas:{
          livros: [
            {id: 'MATRICULA', nome: 'Matrícula'},
            {id: 'PRE_MATRICULA', nome: 'Pré-Matrícula'}
          ]
        },
      }
    },

    computed:{
      title(){
        return FrontBusiness.nomearFicha({livro : 'REGISTRO_AUXILIAR', status : this.status}, false);
      },
      columns(){
        let columns = [
          {label: '', show: this.multiplos, field: 'selecionar', headerClass: 'column__botao', sortable: false, name: 'selecionar'},
          {label: '', field: 'id', headerClass: 'column__botao', sortable: false, name: 'id'},
          {label: this.title, field: 'r.codigo', headerClass: 'column__codigo_doc', name: 'codigo'},
        ]
        if(this.status !== 'PENDENTE'){
          columns.push({label: 'Abertura', field: 'r.abertura', name: 'abertura', minWidth:'200px'},
            {label: 'Encerramento', field: 'r.encerramento', name: 'encerramento', minWidth:'200px'});
        }

        columns.push({label: 'Situação', field: 'r.status', headerClass: 'column__status', name: 'status'},
          {label: 'Tipo Registro', field: 'r.tipo_registro', headerClass: 'column__tipo_registro', name: 'tipoRegistro'});

        return columns;
      },

      livroSelecionado() {
        return this.buscar === 'PRE_MATRICULA' ? 'MATRICULA' : this.buscar;
      },

      statusSelecionado() {
        return this.buscar === 'PRE_MATRICULA' ? 'PENDENTE' : 'ABERTA';
      },

      exportar(){
        let forms = {
          direction:"desc",
          sort:"r.codigo",
          filtros: Utils.clone([...this.filtros?.filtros].filter(e => e.id)),
          preMatricula: this.status == 'PENDENTE',
        };
        return this.mode === 'novo' && !this.auditor ? {id: "EXPORTAR_REGISTRO_AUXILIAR", forms, extensao: "XLSX"} : null;
      },

      actions() {
        let actions = [];
        if (this.auditor) return actions;
        if (this.status !== 'PENDENTE') {
          actions.push({
            ordem: 1,
            class: 'at-options',
            icon: 'far fa-plus-square mr-1',
            label: 'Cadastrar Registro Antigo',
            action: this.cadastrarAntiga
          });
          actions.push({
            ordem: 4,
            class: 'at-options',
            icon: 'far fa-file-search mr-1',
            label: 'Consultar pré-registro auxiliar',
            action: this.consultarPreRegistro
          });
        }
        actions.push({
          ordem: 2,
          class: 'at-options',
          icon: 'far fa-print mr-1',
          label: 'Impressão em Lote',
          action: this.impressaoLote
        });

        actions.push({
          ordem: 3,
          class: 'at-options',
          icon: 'far fa-file-image mr-1',
          label: 'Digitalização em Lote',
          action: this.digitalizacaoLote
        });
        return  Utils.orderBy(actions, 'ordem');
      }
    },

  mounted() {

    let papeis = IndicadorRealBusiness.getPapeisEnvolvidos();
    this.options.tiposPapeis = papeis;
    this.options.tiposPapeis = this.options.todosTiposPapeis.concat(this.options.tiposPapeis)
    this.$set(this.filtros, 'envolvidoPapel', [this.options.tiposPapeis[0].id]);

    this.load();

  },

    methods:{

      async load() {
        this.options.tiposAto = await TipoServicoBusiness.getByDominioLivroSimples('PROTOCOLO_RI', 'REGISTRO_AUXILIAR');
      },

      close(){
        FrontBusiness.closeModal();
      },

      select(registro){
        if (this.seleciona) {
          FrontBusiness.closeModal(registro);
        } else {
          this.detalhes(registro?.id)
        }
      },

      async process(sort_by, sort_dir, page_length, page_number){
        if(this.mode == 'novo'){
          let filtros = Utils.clone([...this.filtros?.filtros].filter(e => e.id));

          let indicador_real_id = filtros.find(e => e.id === 'indicador_real_id');
          if (indicador_real_id) {
            indicador_real_id.value = this.filtros.indicador_real;
          }

          let preMatricula = this.status == 'PENDENTE';

          let envolvido_nome = filtros.find(f => f.id == 'envolvido_nome');

          if(envolvido_nome) {
            envolvido_nome.value = {
              envolvido_nome: envolvido_nome.value,
              envolvido_papel: this.filtros.envolvidoPapel?.filter(c => c !== 'TODOS')
            }
          }
          return RegistroAuxiliarBusiness.paginationV2(sort_by, sort_dir, page_number, page_length, {filtros, preMatricula})
            .then(result => ({rows: result.pagina, total: result.quantidade}))
        }

        let filtros = Utils.clone(this.filtros);

        if (filtros?.codigo) filtros.codigo = Utils.stringCodigosToArray(filtros?.codigo);

        filtros.envolvidoPapel = this.filtros.envolvidoPapel?.filter(c => c !== 'TODOS');

        return RegistroAuxiliarBusiness.pagination(sort_by, sort_dir, page_number, page_length, {filtros : filtros})
          .then(result => ({rows : result.pagina, total: result.quantidade}))
      },

      detalhes(id){
        FrontBusiness.openModal(DetalhesRegistroAuxiliar, {id});
      },

      tipoRegistro(valor){
        return FrontBusiness.getLabel(valor, RegistroAuxiliarBusiness.getTipos());
      },

      formatarCodigo : FrontBusiness.formatarCodigo,

      selecionar(item, nomeFiltro){
        this.$nextTick(() => {
           this.filtros[nomeFiltro] = item ? item.indicadorPessoalId : null;
        });
      },

      remover(idx, lista) {
        this.$nextTick(() => {
          this.filtros[lista].splice(idx, 1);
        });
      },

      selectRegistro(ficha) {
        let index = this.marcados.findIndex(e => e.id === ficha.id);
        if (index >= 0) {
          this.marcados.splice(index, 1)
        } else {
          this.marcados.push(ficha)
        }
      },

      selecionarTodos(limpar = false) {
        this.marcados = [];
        this.$refs?.modal?.$refs?.paginator?.rows.forEach(r => {
          let input = document.getElementById(`check-${r.id}`)
          input.checked = !limpar;
          if (!limpar) this.marcados.push(r)
        })
      },

      async cadastrarAntiga(){
        const indicador = await FrontBusiness.openModal(ModalFichaAntiga, {livro: 'REGISTRO_AUXILIAR'});
        if(indicador){
          FrontBusiness.openModal(DetalhesRegistroAuxiliar, {id: indicador});
        }
      },

      consultarPreRegistro() {
        FrontBusiness.closeModal();
        FrontBusiness.openModal(ConsultarRegistroAuxiliar, {status : 'PENDENTE', seleciona: false}, 1300, true);
      },

      impressaoLote() {
        FrontBusiness.openModal(ImprimirFichaLote, {livro : 'REGISTRO_AUXILIAR'});
      },

      digitalizacaoLote() {
        FrontBusiness.openModal(DigitalizarFichaLote, {livro : 'REGISTRO_AUXILIAR'});
      },

      changePapel(e) {
        let isTodos = !e.length || e[e.length - 1] === 'TODOS';
        this.$set(this.filtros, 'envolvidoPapel', isTodos ? ['TODOS'] : this.filtros.envolvidoPapel.filter(c => c !== 'TODOS'));
        this.$forceUpdate();
      },

      selecionarFichas(nomeFiltro, item, add, x){

        this.$nextTick(() => {
          nomeFiltro = nomeFiltro || x;
          this.filtros[nomeFiltro] = item[0] ? item[0].id : null;
          if (nomeFiltro === 'indicador_real') {
            this[nomeFiltro] = item[0];
          } else {
            this[nomeFiltro] = item[0] ? String(item[0].codigo) + (item[0]?.livroLetra || '') : null;
          }

          if (add && item[0]) {
            this.executeLoad();
          }

        });
      },

    }
  }
</script>

<style lang=scss>
#consultar-indicador-pessoal{
  th.column__botao{
    width: 50px;
  }
}
</style>
